@use "../abstracts" as *;

.links-calendar {
  & + & {
    margin-top: $space-4;
  }

  &__headline {
    display: block;
    margin-bottom: $space-2;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: $space-1_5;
  }

  // The vertical paddings are "magic" numbers because a non-existent CSS property `leading-trim: both;` was used in the design.
  &__link {
    padding: 0.5rem $space-1_5 0.4rem;
  }
}
