@use "../abstracts" as *;

.intro {
  margin-bottom: $space-2_5;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-3;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4;
  }

  .page-opulent__head & {
    margin-bottom: $space-15;
    text-shadow: $text-shadow-small;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-20;
    }
  }
}
