@use "../abstracts" as *;

.consent-reject {
  align-items: flex-start;
  background: var(--color-bg-subtle);
  color: var(--color-text-default);
  display: flex;
  flex-direction: column;
  gap: $space-3;
  padding: $space-2_5 $space-3;

  @include media-breakpoint-up(md) {
    padding: $space-5 $space-4;
  }

  @include media-breakpoint-up(lg) {
    padding: $space-6 $space-5;
  }

  @include media-breakpoint-up(xl) {
    gap: $space-4;
    padding: $space-5;
  }

  &__button {
    border-radius: 0.125rem;
    border: 1px solid var(--color-border-default);
    cursor: pointer;
    padding: $space-1 $space-2_5;
    transition: background-color var(--default-transition-time);

    &:hover {
      background: var(--color-bg-emphasized-subtle);
    }
  }
}
