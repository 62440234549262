@use "../abstracts" as *;

.ad-tag-container {
  &--with-margin:has(iframe),
  &--superbanner_2,
  &--superbanner_3,
  &--teaser_23,
  &--teaser_24,
  &--teaser_25 {
    margin-block: $space-2 $space-3;

    @include media-breakpoint-up(lg) {
      margin-block: $space-3 $space-4;
    }
  }

  &--rectangle_1 {
    inline-size: 300px;
    margin-inline: auto;
    max-inline-size: 300px;

    &:has(+ .text-element, + .subheadline-element) {
      float: inline-end;
      margin-inline-start: $space-6;
    }
  }

  .ad-labels {
    margin-block: 0 $space-0_5;
    margin-inline: auto;
    text-align: start;
  }

  /* if no-ad remove spacing from outstream_div */
  &--outstream_div {
    &:has(.hideOutStreamImportant):not(:has(.GujAdInUse)) {
      margin: 0;
    }
  }
}

/* align ads vertically; example:
    call with ?testZone=lf&adType=teaser_an2_Anzeige_mix&ada=1
  except on opulent articles
*/
.article__body {
  @include media-breakpoint-up(md) {
    .ad-tag-container.GujAdInUse:not(:has(.emsTeaserContainer)) {
      margin-block-start: -1rem;
    }
  }
}
