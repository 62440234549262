@use "../abstracts" as *;

.ad-container {
  &--aside {
    margin-left: -$ad-skyscraper-width;
    position: sticky;
    right: 0;
    top: 0;
    transform: translateX(calc($ad-skyscraper-width + $space-2_5));
    width: fit-content;

    .page-opulent & {
      margin-left: $space-2_5;
      right: revert;
      transform: revert;
      width: $ad-skyscraper-width;
    }
  }

  &--banner {
    margin: $space-2 0 $space-3;

    @include media-breakpoint-up(lg) {
      margin: $space-3 0 $space-4;
    }
  }

  &--section {
    align-items: center;
    gap: $space-px-3;
    justify-content: space-evenly;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }

    /* selects elements with exactly 3 adtags */
    &:has(ws-adtag:nth-of-type(3)):not(:has(ws-adtag:nth-of-type(4))) {
      overflow-x: visible;
      width: 100%;
      justify-content: center;

      @include media-breakpoint-up(md) {
        padding-inline: $space-px-3;
      }

      ws-adtag div:not(.ad-labels) {
        margin-left: auto;
        margin-right: auto;
        max-width: 300px;
      }
    }
  }
}
