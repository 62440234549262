@use "../abstracts" as *;

.group--calendar {
  --group-teaserlist-padding: 0 #{$space-px-3} #{$space-5};

  padding: var(--group-teaserlist-padding);

  @include media-breakpoint-up(md) {
    --group-teaserlist-padding: 0 #{$space-px-5} #{$space-8};
  }

  @include media-breakpoint-up(lg) {
    --group-teaserlist-padding: 0 #{$space-px-10} #{$space-8};
  }
}
