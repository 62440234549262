@use "../abstracts" as *;

.short-text-gallery {
  --short-text-gallery-container-overflow: #{$space-3}; // create visual overflow for slider
  width: calc(100% + var(--short-text-gallery-container-overflow));
  position: relative;
  margin: var(--short-text-gallery-vertical-margin, $space-5) 0;

  @include media-breakpoint-up(md) {
    --short-text-gallery-container-overflow: #{rem(100px)};
    --short-text-gallery-vertical-margin: #{$space-6};
  }

  @include media-breakpoint-up(lg) {
    --short-text-gallery-container-overflow: #{rem(70px)};
    --short-text-gallery-vertical-margin: #{$space-8};
  }

  &__button-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: $font-size-24;
  }

  &__control {
    cursor: pointer;
    background: var(
      --group-teaser-gallery-button-bg,
      var(--color-bg-invert-subtle)
    );
    border-radius: 50%;
    box-shadow: 0 2px 7px 0 var(--color-bg-invert-inset);
    color: var(--color-text-invert);
    display: var(--group-teaser-gallery-button-display, none);
    padding: 0;
    position: absolute;
    top: calc(50% - #{rem(30px)});
    transition: background-color var(--default-transition-time);
    width: $space-6;
    height: $space-6;
    z-index: 4;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }

    &:hover,
    &:focus-visible {
      --group-teaser-gallery-button-bg: var(--color-bg-invert);
      --group-teaser-gallery-button-display: block;
    }

    &.hidden {
      --group-teaser-gallery-button-display: none;
    }
  }

  @media (hover: hover) {
    &:hover,
    &:focus-visible {
      --group-teaser-gallery-button-display: block;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-0_5;
    margin-top: $space-2;
    padding: rem(18px) 0;

    li {
      &.active {
        --button-opacity: 1;
      }

      &.small {
        --button-size: #{rem(6px)};
      }

      &.smaller {
        --button-size: #{$space-0_5};
      }

      button {
        padding: 0;
        display: block;
        width: var(--button-size, $space-1);
        height: var(--button-size, $space-1);
        border-radius: 50%;
        background: var(--color-bg-reversed);
        opacity: var(--button-opacity, 0.3);
        transition: var(--default-transition-time);
        cursor: pointer;
      }
    }
  }

  &__gradient {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(
      var(--short-text-gallery-container-overflow) - $space-1
    ); // minus gap
    height: 100%;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );

    @media (prefers-color-scheme: dark) {
      background: linear-gradient(
        to right,
        rgba(31, 31, 31, 0) 0%,
        rgba(31, 31, 31, 1) 100%
      );
    }

    @include media-breakpoint-up(md) {
      width: calc(
        var(--short-text-gallery-container-overflow) - $space-2
      ); // minus gap
    }
  }

  &__headline {
    margin-right: $space-1_5;
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: $space-1_5;
    padding: $space-4 $space-3;
    flex: 0 0 calc(100% - var(--short-text-gallery-container-overflow));
    border: 1px solid var(--color-border-muted);
    box-shadow: 0 0.5px 2px 0 rgba(0, 0, 0, 0.04);
    max-height: rem(450px);

    &.has-bottom-gradient {
      &::after {
        content: "";
        pointer-events: none;
        width: 100%;
        height: $space-4;
        position: absolute;
        bottom: $space-4;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        opacity: 1;
        transition: all var(--default-transition-time) ease;
      }

      @media (prefers-color-scheme: dark) {
        &::after {
          background: linear-gradient(
            to bottom,
            rgba(31, 31, 31, 0) 0%,
            rgba(31, 31, 31, 1) 100%
          );
        }
      }

      @include media-breakpoint-up(lg) {
        &::after {
          height: $space-5;
          bottom: $space-5;
        }
      }

      &.reached-end {
        &::after {
          opacity: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding: $space-4;
      max-height: rem(400px);
    }

    @include media-breakpoint-up(lg) {
      gap: $space-2;
      padding: $space-5;
    }

    &-content {
      height: auto;
      overflow-y: scroll; /* dont remove this | this will trigger the classList function */

      &:not(.has-overflow) {
        overflow-y: auto; /* remove overflow for not overflowing containers */
      }

      &-headline {
        display: block;
        margin-bottom: $space-1_5;

        @include media-breakpoint-up(lg) {
          margin-bottom: $space-2;
        }
      }
    }
  }
}
