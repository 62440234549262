@use "../abstracts" as *;

.teaser-footer {
  align-items: center;
  color: var(--color-text-inset);
  display: flex;
  gap: $space-1_5;

  .group-teaserblock--with-background-image & {
    color: var(--color-text-invert-alpha);
  }

  &__brand-icon {
    height: $space-2;
  }

  &__ws-bookmarkbutton {
    margin-left: auto;
  }

  &__bookmark {
    aspect-ratio: 1;
    background: var(--teaser-bookmark-bg, transparent);
    border-radius: $border-radius-very-low;
    flex-shrink: 0;
    font-size: $font-size-20;
    margin: -#{$space-0_5} -#{$space-0_5} -#{$space-0_5} auto;
    position: relative;
    transition: background-color var(--default-transition-time);
    width: var(--teaser-bookmark-width, rem(28px));
    z-index: 2; // to place it on top of the teaser `.teaser__link::after`

    button {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0;
      width: 100%;
    }

    .group-teaserblock--with-background-image & {
      color: var(--color-text-invert-alpha);
    }

    @media (hover: hover) {
      &:hover,
      &:focus-visible {
        --teaser-bookmark-bg: var(--color-bg-emphasized-subtle);
      }
    }

    &:active {
      --teaser-bookmark-bg: var(--color-bg-emphasized-moderate);
    }

    .teaser-opulent & {
      --teaser-bookmark-bg: var(--color-bg-invert-emphasized-subtle);
      --teaser-bookmark-width: #{rem(36px)};

      color: var(--color-text-invert);
      margin: 0 0 0 auto;

      @media (hover: hover) {
        &:hover,
        &:focus-visible {
          --teaser-bookmark-bg: var(--color-bg-invert-emphasized-soft);
        }
      }

      &:active {
        --teaser-bookmark-bg: var(--color-bg-invert-emphasized-moderate);
      }
    }
  }
}
