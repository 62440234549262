@use "../abstracts" as *;
@use "../foundation/icons";

.links--toc {
  margin-block-end: $space-6;

  .links__headline {
    align-items: center;
    display: flex;
    gap: $space-1_5;

    &:before {
      @extend .icon-element; // will have to be replaced, see comments in MR / ticket
      align-items: center;
      background-color: var(--color-bg-subtle);
      border-radius: $space-1;
      box-sizing: content-box;
      content: $icon-list-bullets;
      font-size: 1rem;
      padding: $space-1;
    }
  }

  .links__item {
    padding-top: $space_3;
    padding-left: rem(30px);
    position: relative;

    &:before {
      background-color: var(--color-bg-subtle);
      content: "";
      height: 100%;
      left: rem(15px);
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:last-child:before {
      height: rem(34.5px);
    }

    &:after {
      background-color: var(--color-bg-subtle);
      content: "";
      height: 2px;
      left: rem(15px);
      position: absolute;
      top: rem(32.5px);
      width: 15px;

      @include media-breakpoint-up(lg) {
        top: rem(35.5px);
      }
    }
  }

  .links__item-link {
    border-radius: $space-0_5;
    color: var(--color-text-default);
    display: inline-block;
    margin-block: rem(-5px) rem(-4px);
    padding: rem(5px) rem(10px) rem(4px);
    position: relative;
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &:hover {
      background-color: var(--color-bg-subtle);
    }

    &:focus,
    &:active {
      background-color: var(--color-bg-invert-emphasized-moderate);
    }
  }
}
