@use "../abstracts" as *;
@use "../foundation/icons";

.menu {
  --item-height: #{rem(56px)};

  color: var(--color-text-default);
  padding: $space-2 $space-3;

  &__list--indented {
    padding-left: $space-2;
  }

  &__item {
    position: relative;

    &--categories::after {
      @extend .icon-element;
      content: $icon-caret-right;
      font-size: $font-size-14;
      pointer-events: none;
      position: absolute;
      right: $space-0_5;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__headline {
    color: currentColor;
    display: block;
    padding: $space-4 0 $space-0_5;
    text-decoration: none;

    .menu__user-section-nav & {
      padding-top: 0;
    }
  }

  &__link {
    align-items: center;
    border-bottom: 1px solid var(--color-border-subtle);
    color: var(--color-text-default);
    display: flex;
    gap: $space-1_5;
    min-height: var(--item-height);
    text-decoration: none;

    &:has(+ .menu__button) {
      padding-right: $space-5;
    }

    .menu__item--categories > & {
      // create default padding if menu link is higher than min height ($space_1_5)
      padding: $space_1_5 $space-5 $space_1_5 0;
    }

    &--stern-plus {
      position: relative;
      padding: $space-1 $space-2_5;
      background:
        linear-gradient(91deg, #960101 15.81%, #c00 94.57%), lightgray;
      border-radius: $space-0_5;
      color: var(--color-text-invert);

      .menu__icon {
        height: $space-3;
      }

      .icon-chevron-right {
        margin-left: auto;
      }
    }

    &--footer {
      border-radius: $space-0_5;
      border: none;
      cursor: pointer;
      margin: $space-2 $space-1;
      padding: $space-1 $space-2;
      transition: background-color var(--default-transition-time);
      width: max-content;

      &:hover,
      &:focus-visible {
        background-color: var(--color-bg-emphasized-subtle);
      }

      &:active {
        background-color: var(--color-bg-emphasized-moderate);
      }
    }

    [class*="icon-"] {
      font-size: $font-size-24;
    }

    .icon-stern-plus {
      display: flex;

      &::before {
        width: $space-4;
      }
    }
  }

  &__button {
    cursor: pointer;
    height: var(--item-height);
    padding: 0 $space-0_5 0 $space-2;
    position: absolute;
    right: 0;
    top: 0;

    &:before {
      @extend .icon-element;
      content: $icon-caret-down;
    }

    &[aria-expanded="true"]:before {
      content: $icon-caret-up;
    }

    &::after {
      border-left: 1px solid var(--color-border-subtle);
      content: "";
      height: $space-3;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &[aria-expanded="false"] + .menu__list {
      display: none;
    }
  }

  &__marketing {
    display: flex;
    gap: $space-1;
    pointer-events: none;
    position: absolute;
    top: calc($space-0_5 / 2);

    span {
      font-size: $font-size-10;
    }
  }

  &__user-section {
    align-items: center;
    display: flex;
    gap: $space-1_5;
    margin: $space-1_5 0;
    padding: $space-1 $space-3;
  }

  &__user-section-nav {
    padding: $space-1-5 $space-3 $space-2 $space-3;
  }

  &__user-profile-picture {
    aspect-ratio: 1;
    background: var(--color-bg-highlight-subtle);
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    place-content: center;
    width: 2.5rem;
  }

  &__user-text {
    display: flex;
    flex-direction: column;
  }

  &__user-status {
    color: var(--color-text-moderate);
  }

  &__partner-group {
    display: flex;
    gap: $space-1_5;
    margin: $space-3 0 0;
  }

  &__partner-group-item {
    flex: 1;
  }

  // `--background-color` is coming from the feed and set in the template
  &__partner-group-link {
    align-items: center;
    background: var(--background-color);
    border-radius: $space-0_5;
    display: flex;
    height: $space-7;
    justify-content: center;
  }

  &__partner-group-icon {
    height: $space-2_5;
  }

  &__button-group {
    display: flex;
    flex-wrap: wrap;
    gap: $space-1;
  }

  &__button-group-link {
    padding: $space-1 $space-1_5;
    white-space: nowrap;
  }

  &__social-group {
    display: flex;
    font-size: $font-size-24;
    gap: $space-1;
    justify-content: center;
    margin: $space-3 0 $space-2;
  }

  &__social-group-link {
    color: currentColor;
    display: block;
    padding: rem(10px);
    text-decoration: none;
  }
}
