@use "../abstracts" as *;

.links--topic-list {
  padding: $space-5 $space-3 $space-4;

  @include media-breakpoint-up(md) {
    padding: $space-5 $space-5 $space-6;
  }

  @include media-breakpoint-up(lg) {
    padding: $space-5 $space-10 $space-6;
  }

  @include media-breakpoint-up(xl) {
    padding: $space-8 $space-10 $space-6;
  }

  .links__items {
    display: grid;
    grid-template-columns: 1fr;
    gap: $space-3 0;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
      gap: $space-3 $space-4;
    }

    .links__item {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
        max-width: none;
      }

      a {
        color: var(--color-text-default);
      }
    }
  }
}
