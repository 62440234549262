@use "../abstracts" as *;

.header {
  &__navigation {
    display: grid;
    grid-template-columns: 1fr var(--header-logo-width, 2.875rem) 1fr;
    margin: 0 auto;
    padding: $space-px-1_5 $space-px-1;

    @include media-breakpoint-up(md) {
      padding: $space-px-2 $space-px-3;
    }

    @include media-breakpoint-up(xl) {
      --header-logo-width: #{3.563rem};

      max-width: $max-content-width + $space-px-4;
      padding: $space-px-2_5 0;
    }
  }

  &__user-area {
    align-items: center;
    display: flex;
    justify-self: end;
  }

  &__button {
    align-items: center;
    display: inline-flex;
    line-height: $space-5;

    &--menu {
      align-self: center;
      gap: $space-1;
      justify-self: start;
      padding-inline: $space-2;
    }

    &--subscription {
      color: var(--color-text-highlight);
      padding-inline: $space-2;

      .page-opulent & {
        color: var(--color-text-invert);
      }
    }

    &--user {
      aspect-ratio: 1;
      flex-wrap: wrap;
      padding: 0;
      place-content: center;
      width: $space-5;

      &[data-drawertarget] {
        background: var(--color-bg-highlight-subtle);

        .page-opulent & {
          background: var(--color-bg-invert-emphasized-subtle);
          color: var(--color-text-invert);
        }
      }
    }
  }

  &__button-icon::before {
    font-size: $font-size-24;
  }

  &__logo {
    aspect-ratio: 46 / 64;
    background: var(--color-bg-highlight) var(--stern-logo) center/contain
      no-repeat;
  }

  &__top-bar {
    background-color: var(--color-bg-subtle);
    padding: 0.6rem 0; // 0.6rem is a magic number

    .page-opulent & {
      background-color: transparent;
      border: solid var(--color-border-muted);
      border-width: 1px 0;
    }

    @include media-breakpoint-up(md) {
      padding-inline: $space-px-3;
    }
  }

  &__top-bar-inner {
    @include media-breakpoint-up(xl) {
      margin: 0 auto;
      max-width: $max-content-width + $space-px-4;
    }
  }

  &__top-item {
    color: inherit;
    display: block;
    padding: 0.3rem $space-1_5; // 0.3rem is a magic number
    text-decoration: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__interaction-element {
    cursor: pointer;
    transition: background-color var(--default-transition-time);
    border-radius: $space-0_5;

    &.header__button--user {
      border-radius: 50%;
    }

    &:hover,
    &:focus-visible {
      background-color: var(--color-bg-emphasized-subtle);

      .page-opulent & {
        background-color: var(--color-bg-invert-emphasized-subtle);
      }
    }

    &:active {
      background-color: var(--color-bg-emphasized-moderate);

      .page-opulent & {
        background-color: var(--color-bg-invert-emphasized-moderate);
      }
    }
  }
}
