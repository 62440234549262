@use "../abstracts" as *;

.picture {
  > img {
    height: 100%;
    width: 100%;
  }

  &--lead-content,
  &--standard-l {
    aspect-ratio: 1;

    @include media-breakpoint-up(md) {
      aspect-ratio: 1.7778;
    }
  }

  &--opulent-l {
    aspect-ratio: 0.75;

    @include media-breakpoint-up(md) {
      aspect-ratio: 1.5;
    }

    @include media-breakpoint-up(lg) {
      aspect-ratio: 1.7778;
    }
  }

  &--list-element {
    aspect-ratio: 1.3333;

    @include media-breakpoint-up(md) {
      aspect-ratio: 1.5;
    }
  }
}
