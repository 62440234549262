@use "../abstracts" as *;
@use "../foundation/icons";

.group-teaserlist {
  --group-teaserlist-padding: #{$space-4} #{$space-px-3};

  background-color: var(--color-bg-secondary);
  box-shadow: $content-shadow;
  margin-bottom: var(--group-teaserlist-margin-bottom, $space-1_5);
  padding: var(--group-teaserlist-padding);
  position: relative;

  @include media-breakpoint-up(md) {
    --group-teaserlist-margin-bottom: #{$space-3};
    --group-teaserlist-padding: #{$space-5} #{$space-px-5};
  }

  @include media-breakpoint-up(lg) {
    --group-teaserlist-padding: #{$space-8} #{$space-px-10};
  }

  &__headline {
    margin-bottom: $space-4;

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-5;
    }

    &-link {
      color: var(--color-text-default);
      text-decoration: none;

      &::after {
        @extend .icon-element;
        content: $icon-arrow-right;
        color: var(--color-text-muted);
        display: inline;
        font-size: rem(16px);
        margin-left: $space-1;
        transition: all 0.3s ease;
        transform: translateY(-0.1em);
      }

      &:hover::after {
        color: var(--color-text-highlight);
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: $space-3;
  }

  /* 'relateds' teaserlists are equivalent to 'standard-s' teaserblock */
  &--relateds,
  &--special-relateds {
    .group-teaserlist__items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(230px, 100%), 1fr));
      gap: var(--group-teaserlist-gap);
    }
  }

  /* 'relateds' contain only standard teasers */
  &--relateds {
    --group-teaserlist-gap: #{$space-4} #{$space-3};

    @include media-breakpoint-up(md) {
      --group-teaserlist-gap: #{$space-5} #{$space-4};
    }

    @include media-breakpoint-up(lg) {
      --group-teaserlist-gap: #{$space-6} #{$space-5};
    }
  }

  /* 'special relateds' can contain standard teasers and plaintext teasers */
  &--special-relateds {
    --group-teaserlist-gap: #{$space-3};

    @include media-breakpoint-up(md) {
      --group-teaserlist-gap: #{$space-3} #{$space-4};
    }

    @include media-breakpoint-up(lg) {
      --group-teaserlist-gap: #{$space-3} #{$space-5};
    }

    .teaser {
      margin-bottom: $space-1;

      @include media-breakpoint-up(md) {
        margin-bottom: $space-2;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: $space-3;
      }
    }

    .teaser-plaintext {
      grid-column: 1 / -1;
    }
  }

  &:has(+ .pagination) {
    margin-bottom: 0;
  }
}
