@use "../abstracts" as *;

ws-paywall {
  --ws-paywall-placeholder-height: #{rem(350px)};

  margin: 0 calc(-1 * var(--article-column-gap))
    var(--paywall-margin-bottom, $space-10);

  @include media-breakpoint-up(md) {
    --paywall-margin-bottom: calc(2 * #{$space-12});
    --paywall-shadow-height: #{$space-12};
  }

  .dialog & {
    .paid-barrier {
      width: 100%;
      margin: 0;
    }

    &::after {
      content: none;
    }
  }

  &:not(:defined) .paid-barrier * {
    opacity: 0;
  }

  &::before {
    background: linear-gradient(
      180deg,
      var(--color-fade-paywall-end) 0%,
      var(--color-fade-paywall-middle) 40%,
      var(--color-fade-paywall-start) 90%
    );
    content: "";
    height: var(--paywall-shadow-height, #{$space-10});
    inset: auto 0 100%;
    position: absolute;
  }
}
