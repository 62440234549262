/* COLORS */

/* Use the primitive tokens only for the semantic tokens in the foundation. */
/* The sorting of the tokens should correspond to the Figma file for the design system. */

/* Neutral Primitives */
$neutral-095: #191919;
$neutral-093: #1f1f1f;
$neutral-092: #282828;
$neutral-090: #313131;
$neutral-080: #545454;
$neutral-070: #6e6e6e;
$neutral-060: #767676;
$neutral-050: #a1a0a0;
$neutral-040: #bbbbbb;
$neutral-030: #d4d4d4;
$neutral-025: #e8e8e8;
$neutral-020: #f3f3f3;
$neutral-010: #f7f7f7;

/* B/W Primitives */
$black: #111111;
$white: #ffffff;

/* Focus Outline Blue */
$blue-focus: #2c6fe4;

/* stern Red Primitives */
$red-090: #310e00;
$red-080: #4d0e00;
$red-070: #801000;
$red-060: #b20e00;
$red-050: #cc0000;
$red-045: #dc0808;
$red-040: #ed5e5e;
$red-030: #f28c8c;
$red-020: #f7baba;
$red-010: #fae5e5;

/* Brand Colors Primitives */
$geo-green: #6cc24a;
$capital-gold: #b09046;
$crime-black: #000000;

/* CONTENT SHADOW */
$content-shadow: 0 80px 200px rgba(17, 17, 17, 0.05);
