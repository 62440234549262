$icon-affiliate: "\e901";
$icon-arrow-down: "\e902";
$icon-arrow-left: "\e903";
$icon-arrow-right: "\e904";
$icon-arrow-up: "\e905";
$icon-arrow-up-right: "\e906";
$icon-audio: "\e907";
$icon-bookmark: "\e908";
$icon-bookmark-filled: "\e909";
$icon-caret-down: "\e90a";
$icon-caret-left: "\e90b";
$icon-caret-right: "\e90c";
$icon-caret-up: "\e90d";
$icon-cart: "\e900";
$icon-check: "\e90e";
$icon-check-default: "\e90f";
$icon-chevron-down: "\e910";
$icon-chevron-left: "\e911";
$icon-chevron-right: "\e912";
$icon-chevron-up: "\e913";
$icon-close: "\e93c";
$icon-email: "\e914";
$icon-external: "\e915";
$icon-eye: "\e916";
$icon-eye-closed: "\e917";
$icon-facebook: "\e918";
$icon-gallery: "\e919";
$icon-games: "\e91a";
$icon-headphones: "\e91b";
$icon-headset: "\e91c";
$icon-home: "\e91d";
$icon-info: "\e91e";
$icon-instagram: "\e91f";
$icon-lightbox: "\e920";
$icon-link: "\e921";
$icon-list-bullets: "\e922";
$icon-lock: "\e923";
$icon-magazines: "\e924";
$icon-menu: "\e925";
$icon-pause: "\e926";
$icon-placeholder: "\e927";
$icon-play: "\e928";
$icon-podcast: "\e929";
$icon-product-comparisons: "\e92a";
$icon-profile: "\e92b";
$icon-push-notification: "\e92c";
$icon-quote: "\e92d";
$icon-share-android: "\e92e";
$icon-share-ios: "\e92f";
$icon-sign-in: "\e930";
$icon-sign-out: "\e931";
$icon-stern-studien: "\e933";
$icon-tiktok: "\e934";
$icon-user-list: "\e935";
$icon-video: "\e936";
$icon-waveform: "\e937";
$icon-whatsapp: "\e938";
$icon-x: "\e939";
$icon-xing: "\e93a";
$icon-youtube: "\e93b";
