@use "../abstracts" as *;

ws-scroller:not(:defined) :is([slot="previous"], [slot="next"]) {
  display: none;
}

ws-scroller:not(:defined) {
  display: grid;
}

.ws-scroller {
  --ws-scroller-mask-image-both: linear-gradient(
    to right,
    transparent #{$space-4},
    #000 #{$space-12} calc(100% - #{$space-12}),
    transparent calc(100% - #{$space-4})
  );
  --ws-scroller-mask-image-next: linear-gradient(
    to right,
    #000 calc(100% - #{$space-12}),
    transparent calc(100% - #{$space-4})
  );
  --ws-scroller-mask-image-previous: linear-gradient(
    to right,
    transparent #{$space-4},
    #000 #{$space-12}
  );

  &__list {
    display: flex;
    flex-wrap: nowrap; // prevent line breaking
    overflow: auto hidden;
    scrollbar-width: none;
    padding: 0.125rem; // 2px, necessary for outline on focus-visible.

    &::-webkit-scrollbar {
      display: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }

  &__button {
    align-items: center;
    border-radius: $space-0_5;
    cursor: pointer;
    display: inline-flex;
    font-size: $font-size-20;
    gap: $space-1;
    height: $space-4;
    justify-content: center;
    line-height: 1;
    transition: background-color var(--default-transition-time);
    width: $space-4;

    :where(&:hover, &:focus-visible) {
      background-color: var(--color-bg-emphasized-subtle);

      .page-opulent & {
        background-color: var(--color-bg-invert-emphasized-subtle);
      }
    }

    &:active {
      background-color: var(--color-bg-emphasized-moderate);

      .page-opulent & {
        background-color: var(--color-bg-invert-emphasized-moderate);
      }
    }
  }

  &--alt {
    .ws-scroller__button {
      [class*="icon-"] {
        color: var(--color-text-muted);
      }
    }
  }
}
