@use "../abstracts" as *;
@use "../foundation/typography";

.group--linkbox {
  --group-linkbox-margin-bottom: #{$space-1_5};
  --group-linkbox-padding: #{$space-4} #{$space-px-3};

  background-color: var(--color-bg-secondary);
  box-shadow: $content-shadow;
  display: flex;
  flex-direction: column;
  gap: $space-4;
  padding: var(--group-linkbox-padding);
  position: relative;

  &:not(:last-child) {
    margin-bottom: var(--group-linkbox-margin-bottom);
  }

  @include media-breakpoint-up(md) {
    --group-linkbox-margin-bottom: #{$space-3};
    --group-linkbox-padding: #{$space-5} #{$space-px-5};
  }

  @include media-breakpoint-up(lg) {
    --group-linkbox-padding: #{$space-8} #{$space-px-10};
  }

  .group__items {
    display: flex;
    flex-direction: column;
    gap: $space-4;
  }

  .links__headline {
    margin-right: $space-1;
  }

  .links__items {
    display: inline;
  }

  .links__item {
    display: inline;
    line-height: var(--links-item-line-height);

    &:not(:last-child):after {
      color: var(--color-text-subtle);
      content: "•";
      margin-right: $space-1_5;
    }

    &-link {
      color: var(--color-text-default);
      margin-right: $space-1_5;

      &--with-ads {
        text-decoration: none;

        .links__item-pseudo-link {
          text-decoration: underline;
        }

        // \00a0 = non-breaking space to prevent having word "Anzeige" on a new line
        &::after {
          @extend .typo-ad-label;
          color: var(--color-text-moderate);
          content: "\00a0\00a0 Anzeige";
        }
      }
    }
  }

  .links--linkbox-category {
    /**
    * line-height according to Figma: $line-height-130;
    * But the desired element flow makes the `display: inline` property necessary at key points.
    * This means that the vertical gap of 8px cannot be implemented. In order to match the design as closely as possible, this value has been increased.
    * (Results from measurements here: https://www.figma.com/design/oJZtzbsIhsfOhrT8A7TWpm/Design-System?node-id=7239-79079&m=dev)
    * @see: https://rtltech.atlassian.net/browse/WEB-671?focusedCommentId=1396955
    */
    --links-item-line-height: #{rem(35px)};
  }
}
