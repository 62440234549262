@use "../abstracts" as *;

.image-element {
  &__image {
    width: 100%;
  }

  &__caption {
    margin-block-start: $space-1_5;

    @include media-breakpoint-up(lg) {
      margin-block-start: $space-2;
    }
  }

  &__description {
    color: var(--color-text-default);
  }

  &__credits {
    color: var(--color-text-moderate);
    margin-block-start: $space-0_5;
  }

  &--lead-content {
    .image-element__caption {
      display: grid;
      grid-template-columns: var(--article-grid-template-columns);
      column-gap: var(--article-column-gap);
      padding-inline: var(--article-padding-inline);
    }

    :is(.image-element__description, .image-element__credits) {
      grid-column: var(--article-body-grid-column);
    }
  }

  /* Images directly embedded in article text. */
  .article__body > & {
    clear: both;
    margin: $space-5 (-$space-px-3);

    @include media-breakpoint-up(md) {
      margin: $space-6 var(--article-body-reverse-indent-1-column);
    }

    @include media-breakpoint-up(lg) {
      margin-block: $space-8;
    }

    .image-element__caption {
      padding-inline: $space-px-3;

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: var(--article-grid-template-columns);
        column-gap: var(--article-column-gap);
        padding-inline: 0;

        :is(.image-element__description, .image-element__credits) {
          grid-column: var(--article-body-grid-column);
        }
      }
    }
  }
}
