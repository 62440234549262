@use "../abstracts" as *;

.ws-snackbar {
  --ws-snackbar-action-background-color-active: var(
    --color-bg-reversed-moderate
  );
  --ws-snackbar-action-background-color-hover: var(--color-bg-reversed-subtle);
  --ws-snackbar-action-border-radius: #{$border-radius-low};
  --ws-snackbar-action-color: var(--color-text-reversed);
  --ws-snackbar-action-font-weight: #{$font-weight-500};
  --ws-snackbar-background: var(--color-bg-reversed);
  --ws-snackbar-border-radius: #{$border-radius-low};
  --ws-snackbar-box-shadow: #{$content-shadow};
  --ws-snackbar-label-color: var(--color-text-reversed);
  --ws-snackbar-padding: #{$space-1_5} #{$space-1} #{$space-1_5} #{$space-2_5};

  z-index: 2;

  @include media-breakpoint-up(md) {
    --ws-snackbar-padding: #{$space-1_5} #{$space-2} #{$space-1_5} #{$space-3};
  }
}
