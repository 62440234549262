@use "../abstracts" as *;

.index {
  &__marketing {
    display: flex;
    gap: $space-1;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }
}
