@use "../abstracts" as *;
@use "../foundation/typography";

.group-gallery-item {
  background-color: var(--color-bg-invert-soft);
  padding-bottom: $space-2_5;

  @include media-breakpoint-up(md) {
    /* 100% of the figcaption minus the navigation buttons minus space from design */
    --group-gallery-item-description-width: calc(100% - 100px - #{$space-px-8});

    padding-bottom: $space-4;

    .group-inline-gallery & {
      padding-bottom: $space-2;
    }
  }

  :is(.group-gallery, .group-inline-gallery--index-gallery) & {
    @include media-breakpoint-up(lg) {
      --group-gallery-item-description-width: calc(
        100% - 100px - #{$space-px-12}
      );
    }
  }

  &__bounding-box {
    aspect-ratio: 1;
    position: relative;

    @include media-breakpoint-up(md) {
      aspect-ratio: 1.7778;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background:
        radial-gradient(
          171.5% 100.85% at 63.08% 0%,
          rgba(25, 25, 25, 0) 55.21%,
          rgba(25, 25, 25, 0.03) 65%,
          rgba(25, 25, 25, 0.5) 100%
        ),
        linear-gradient(
          0deg,
          rgba(25, 25, 25, 0.8) 0%,
          rgba(25, 25, 25, 0.03) 22%,
          rgba(25, 25, 25, 0) 30%
        ),
        linear-gradient(
          0deg,
          #191919 0%,
          rgba(25, 25, 25, 0.1) 25%,
          rgba(25, 25, 25, 0) 30%
        );
    }

    &:has(ws-clamp[expanded]) {
      &::after {
        background:
          radial-gradient(
            171.5% 100.85% at 63.08% 0%,
            rgba(25, 25, 25, 0) 55.21%,
            rgba(25, 25, 25, 0.03) 65%,
            rgba(25, 25, 25, 0.5) 100%
          ),
          linear-gradient(
            0deg,
            rgba(25, 25, 25, 0.8) 0%,
            rgba(25, 25, 25, 0.03) 22%,
            rgba(64, 64, 64, 0) 30%
          ),
          linear-gradient(
            0deg,
            #191919 0%,
            rgba(25, 25, 25, 0.1) 25%,
            rgba(25, 25, 25, 0) 30%
          ),
          linear-gradient(
            90deg,
            rgba(25, 25, 25, 0.8) 0%,
            rgba(25, 25, 25, 0.8) 100%
          );
      }
    }
  }

  &__figure {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .picture {
      height: 100%;
      width: 100%;
    }
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__caption {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    inset: auto auto 0 0;
    height: calc(100% - $space-3);
    overflow: hidden;
    z-index: 1;
    margin-inline: var(--ws-gallery-space-horizontal);
    width: calc(
      100% - 2 * (var(--ws-gallery-space-horizontal))
    ); //100% width of the figurelement minus the margin on both sides

    @include media-breakpoint-up(md) {
      height: calc(100% - $space-5);
      margin-bottom: 0;
    }
  }

  &__copy {
    height: rem(23px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: $space-1;
    }
  }

  &__credits {
    color: var(--color-text-invert-muted);

    &:not(:first-child) {
      margin-top: $space-2;
    }
  }

  &__description {
    color: var(--color-text-invert);
    max-height: 100%;
    padding-block: $space-4 $space-0_5;
    width: var(--group-gallery-item-description-width);

    @include media-breakpoint-up(md) {
      padding-block: 0 $space-1;
    }

    &[truncated] {
      cursor: pointer;
    }

    &[expanded] {
      cursor: default;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      &::part(container) {
        overflow-y: auto;
        width: var(--group-gallery-item-description-width);

        //This is the scrollbar styling for Chrome and Firefox
        scrollbar-color: var(--color-bg-default) rgba(0, 0, 0, 0);
        scrollbar-width: thin;

        //This is the scrollbar styling for Safari
        &::-webkit-scrollbar {
          width: $space-0_5;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--color-bg-default);
          border-radius: 12px;
        }
      }

      .group-gallery-item__copy {
        height: auto;
        white-space: normal;
      }
    }
  }

  &__button {
    &--expand {
      cursor: pointer;
      padding-inline: 0;

      @include media-breakpoint-up(md) {
        right: $space-15;
      }

      @include media-breakpoint-up(lg) {
        right: $space-10;
      }
    }

    &--collapse {
      cursor: pointer;
      font-size: $font-size-24;
      padding: 0;
    }
  }

  &--adtag {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .ad-tag-container {
      margin: 0;
      z-index: 1;

      .ad-labels {
        inset: $space-1 auto auto $space-1;
        position: absolute;
      }
    }

    &::before {
      @extend .typo-info-head;
      color: var(--color-text-invert);
      content: "Anzeige";
      inset: 50% auto auto 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
