@use "../abstracts" as *;

.group {
  /*
    Show elements as 'aside' (aka 'Tanzspalte') only for 'image-element' and 'list-element'.
    The setting 'aside-right' is only possible for images in the CMS.
    Ignore these settings for other elements, e.g. teasers or short-text-galleries.
  */
  &--aside:has(.image-element--aside, .list-element--aside),
  &--aside-right:has(.image-element--aside) {
    margin-block: $space-5;

    @include media-breakpoint-up(md) {
      width: calc(
        (var(--article-body-grid-column-width) * 5) +
          (var(--article-column-gap) * 4)
      );
    }
  }

  &--aside:has(.image-element--aside),
  &--aside-right:has(.image-element--aside) {
    margin-inline: $space-4;
  }

  &--aside:has(.image-element--aside, .list-element--aside) {
    @include media-breakpoint-up(md) {
      float: left;
      margin: 0 var(--article-column-gap) $space-2
        var(--article-body-reverse-indent-1-column);
    }
  }

  &--aside-right:has(.image-element--aside) {
    @include media-breakpoint-up(md) {
      float: right;
      margin: 0 var(--article-body-reverse-indent-1-column) $space-2
        var(--article-column-gap);
    }
  }

  /*
    The setting 'centered' is only possible for images in the CMS.
  */
  &--center:has(.image-element--center) {
    clear: both;
    margin-block: $space-5;

    @include media-breakpoint-up(md) {
      width: calc(
        (var(--article-body-grid-column-width) * 8) +
          (var(--article-column-gap) * 7)
      );
      margin: $space-6 auto;
    }

    @include media-breakpoint-up(lg) {
      margin-block: $space-8;
    }
  }

  /* If an advertisement is displayed in the teaserblock, the fallback teaser should be hidden. */
  &--adtag-with-fallbackcontent {
    .GujAdInUse + .teaser {
      display: none;
    }
  }
}
