@use "../abstracts" as *;

.list-element {
  --list-element-caption-margin: #{$space_2} #{$space-3} 0;
  --list-element-image-margin-bottom: #{-$space-1_5};
  --list-element-margin: #{$space-2_5} 0;
  --list-element-text-margin: #{$space-4} #{$space-3};

  .article__body > & {
    clear: both;
    margin: var(--list-element-margin);
  }

  @include media-breakpoint-up(md) {
    --list-element-image-margin-bottom: #{-$space-1};
    --list-element-margin: #{$space-6} 0;
  }

  @include media-breakpoint-up(lg) {
    --list-element-margin: #{$space-8} 0;
  }

  &:not(&--aside) {
    --list-element-text-gap: #{$space-2};

    @include media-breakpoint-up(md) {
      --list-element-caption-margin: #{$space_2} #{$space-4} 0;
      --list-element-text-margin: #{$space-4};
    }

    @include media-breakpoint-up(lg) {
      --list-element-caption-margin: #{$space_2} #{$space-5} 0;
      --list-element-image-margin-bottom: #{-$space-2};
      --list-element-text-margin: #{$space-5};
    }
  }

  &--aside {
    --list-element-text-gap: #{$space-1_5};

    @include media-breakpoint-up(md) {
      --list-element-caption-margin: #{$space_1} #{$space-2} 0;
      --list-element-text-margin: #{$space-2_5} #{$space-2};

      // Next two blocks are overwriting the typography classes from the template
      // because on S breakpoints we want to use different typography settings
      .list-element__headline {
        // @extend is preferred way, but unfortunately it is not working
        // because sass compiler doesn't allow to use extend in media queries
        //@extend .typo-headline4;
        font-weight: $font-weight-700;
        font-size: $font-size-20;
        line-height: $line-height-130;
      }

      .list-element__copy {
        // @extend is preferred way, but unfortunately it is not working
        // because sass compiler doesn't allow to use extend in media queries
        //@extend .typo-body-small;
        font-size: $font-size-15;
        line-height: $line-height-150;
      }
    }

    @include media-breakpoint-up(lg) {
      --list-element-caption-margin: #{$space_1} #{$space-2_5} 0;
      --list-element-image-margin-bottom: #{-$space-1_5};
      --list-element-text-margin: #{$space-3} #{$space-2_5};
    }
  }

  .list-element__content {
    border: 1px solid var(--color-border-muted);
  }

  &__marketing {
    margin-bottom: $space-0_5;
  }

  &__image-wrapper {
    margin: -1px -1px var(--list-element-image-margin-bottom); // -1px is to cover the border of the list element
  }

  .image-element__caption {
    margin: var(--list-element-caption-margin);
  }

  .image-element__credits {
    margin: 0;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: var(--list-element-text-gap);
    margin: var(--list-element-text-margin);
  }

  a {
    color: currentColor;
  }
}
