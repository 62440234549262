@use "../abstracts" as *;

.pagination {
  position: relative;
  padding: $space-3 $space-3 $space-6;
  background: var(--color-bg-default);
  margin-bottom: $space-1;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-2;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-3;
  }

  &--alt {
    .pagination__control--prev {
      margin-right: auto;
    }

    .pagination__control--next {
      margin-left: auto;
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-4;
  }

  a {
    color: var(--color-text-default);
    display: flex;
    gap: $space-1;

    [class*="icon-"] {
      font-size: $font-size-20;
    }
  }

  &__pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      a {
        position: relative;
        display: block;
        padding: 0 $space-2 rem(13px);
        color: var(--color-text-muted);
        text-decoration: none;
        text-underline-offset: 4px;
      }

      &.pagination__page--active {
        a {
          color: var(--color-text-highlight);
          font-weight: $font-weight-500;
          text-decoration: underline;
        }
      }

      &.pagination__page--skip {
        color: var(--color-text-subtle);

        &::before {
          content: "\2026";
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: $space-3 $space-5 $space-6;

    &__controls {
      margin-bottom: 0;
    }

    &__pages {
      position: absolute;
      top: $space-3;
      left: 50%;
      transform: translateX(-50%);

      li {
        a {
          /* get the half of 12px */
          padding: 0 calc($space-1_5 / 2) 0;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: $space-3 $space-10 $space-6;

    a,
    a::before {
      transition: all var(--default-transition-time) ease;
    }

    &__control {
      a {
        &:hover {
          color: var(--color-text-inset);
        }

        &:focus {
          color: var(--color-text-default);
        }
      }
    }

    &__pages {
      li {
        a {
          &:hover {
            color: var(--color-text-highlight);
          }

          &:focus {
            font-weight: $font-weight-500;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
