@use "tools" as *;

/* TYPOGRAPHY */

/* Use the primitive tokens only for the semantic tokens in the foundation. */

/* FONT-FAMILY */
$fallbackFont: Arial, Helvetica, sans-serif !default;
$font-family-stern: "stern-variable", $fallbackFont;
$font-family-icons: "iconfont";

/* FONT-WEIGHTS */
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-800: 800;

/* FONT-STRETCH */
$font-stretch-80: 80%;
$font-stretch-100: 100%;

/* DEFAULT FONT SIZE */

/* FONT SIZES (base 16px font-size)*/

$font-size-60: rem(60px);
$font-size-56: rem(56px);
$font-size-48: rem(48px);
$font-size-44: rem(44px);
$font-size-40: rem(40px);
$font-size-36: rem(36px);
$font-size-32: rem(32px);
$font-size-28: rem(28px);
$font-size-26: rem(26px);
$font-size-24: rem(24px);
$font-size-22: rem(22px);
$font-size-20: rem(20px);
$font-size-18: rem(18px);
$font-size-17: rem(17px);
$font-size-16: rem(16px) !default; // base font-size
$font-size-15: rem(15px);
$font-size-14: rem(14px);
$font-size-13: rem(13px);
$font-size-12: rem(12px);
$font-size-10: rem(10px);

/* LINE-HEIGHTS */
$line-height-100: 1;
$line-height-110: 1.1;
$line-height-120: 1.2;
$line-height-130: 1.3;
$line-height-150: 1.5;
$line-height-160: 1.6;

/* LETTER SPACING */
$letter-spacing-densed: -0.01em;
$letter-spacing-normal: 0;
$letter-spacing-loose: 0.01em;

/* TEXT TRANSFORM */
$text-transform-uppercase: uppercase;
$text-transform-none: none;

/* TEXT STYLE */
$text-style-italic: italic;
$text-style-normal: normal;

/* TEXT SHADOW */
$text-shadow-small: 0 1px 4px rgba(0, 0, 0, 0.25);
$text-shadow-large: 0 2px 7px rgba(0, 0, 0, 0.25);
