@use "../abstracts" as *;

/* typo-seo-tag-head is greater than typo-seo-tags:
- one bases size
- so distance between links is always equal
*/
$linkHeight: rem(33px);

.links.index__tags {
  display: flex;
  background: var(--color-bg-default);
  padding: $space-3;
  margin-bottom: $space-1;

  @include media-breakpoint-up(md) {
    padding: $space-3 $space-5;
  }

  @include media-breakpoint-up(lg) {
    padding: $space-4 $space-10 $space-3;
    margin-bottom: $space-1_5;
  }

  .links {
    &_items {
      display: flex;
      flex-wrap: wrap;
    }

    &_item {
      position: relative;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: $space-1;

      &::after {
        content: "•";
        position: relative;
        display: block;
        font-size: $font-size-17;
        color: var(--color-text-subtle);
        margin: 0 rem(12px);
      }

      &-link {
        display: flex;
        align-items: center;
        height: $linkHeight;
        color: var(--color-text-default);
      }

      &:first-child {
        margin-right: $space-1_5;
      }

      &:first-child,
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
