@use "../abstracts" as *;
@use "../foundation/icons";

.title {
  margin-bottom: $space-1_5;

  @include media-breakpoint-up(xl) {
    margin-bottom: $space-2;
  }

  .article--geo & {
    --color-kicker: var(--color-text-highlight-geo);
  }

  .article--capital & {
    --color-kicker: var(--color-text-highlight-capital);
  }

  .article--str_crime & {
    --color-kicker: var(--color-text-default);
  }

  .page-opulent & {
    margin-bottom: $space-3;
    text-align: center;
  }

  &__marketing {
    display: flex;
    gap: 0 $space-1;
    margin: 0 0 $space-2 0;

    @include media-breakpoint-up(md) {
      float: right;
      margin: 0 0 0 $space-1_5;
    }
  }

  &__kicker {
    color: var(--color-kicker, var(--color-text-highlight));
    display: block;
    margin-bottom: $space-1;

    .page-opulent & {
      color: var(--color-text-invert);
      margin-bottom: $space-2;
      text-shadow: $text-shadow-small;
    }
  }

  &__headline {
    .page-opulent & {
      text-shadow: $text-shadow-large;
    }
  }

  &__headline--paid-marker {
    &::before {
      margin-right: 0.25em;
      transform: translateY(var(--title-paid-marker-translate-y, 0.1em));
      width: var(--title-paid-marker-width, $space-5);
    }

    &.typo-headline1::before {
      @include media-breakpoint-up(md) {
        --title-paid-marker-width: #{$space-7};
      }

      @include media-breakpoint-up(xl) {
        --title-paid-marker-translate-y: 0.05em;
        --title-paid-marker-width: #{$space-8};
      }
    }

    &.typo-opulent-short::before {
      @include media-breakpoint-up(md) {
        --title-paid-marker-translate-y: 0.05em;
      }

      @include media-breakpoint-up(lg) {
        --title-paid-marker-translate-y: 0.125em;
        --title-paid-marker-width: #{$space-7};
      }

      @include media-breakpoint-up(xl) {
        --title-paid-marker-translate-y: 0.1em;
      }
    }

    &.typo-opulent-long::before {
      --title-paid-marker-width: #{$space-4};

      @include media-breakpoint-up(md) {
        --title-paid-marker-translate-y: 0.05em;
      }

      @include media-breakpoint-up(lg) {
        --title-paid-marker-translate-y: 0.1em;
        --title-paid-marker-width: #{$space-5};
      }

      @include media-breakpoint-up(xl) {
        --title-paid-marker-translate-y: 0.05em;
      }
    }
  }
}
