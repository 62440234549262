:root {
  --capital-logo: url(@client/images/logo.capital.svg);
  --capital-white-logo: url(@client/images/logo.capital-white.svg);
  --geo-logo: url(@client/images/logo.geo.svg);
  --geo-white-logo: url(@client/images/logo.geo-white.svg);
  --stern-logo: url(@client/images/logo.str.svg);
  --str_crime-logo: url(@client/images/logo.str_crime.svg);
  --str_crime-white-logo: url(@client/images/logo.str_crime-white.svg);
  --str_plus-logo: url(@client/images/logo.str_plus.svg);

  // Aspect ratios (width and height) are taken from the SVG files
  --capital-logo-aspect-ratio: 118 / 36;
  --geo-logo-aspect-ratio: 76 / 36;
  --stern-logo-aspect-ratio: 46 / 64;
  --str_crime-logo-aspect-ratio: 139 / 36;
  --str_plus-logo-aspect-ratio: 64 / 46;
}

/* Use white brand identifier logos in dark mode. */
@media (prefers-color-scheme: dark) {
  :root {
    --capital-logo: var(--capital-white-logo);
    --geo-logo: var(--geo-white-logo);
    --str_crime-logo: var(--str_crime-white-logo);
  }
}
