@use "sass:map";

$breakpoints: (
  sm: 0,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

// Mixin to handle media queries
@mixin media-breakpoint-up($breakpoint) {
  @media (min-width: map.get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  $max-width: map.get($breakpoints, $breakpoint) - 1;

  @media (max-width: $max-width) {
    @content;
  }
}

@mixin media-breakpoint-between($min-breakpoint, $max-breakpoint) {
  $min-width: map.get($breakpoints, $min-breakpoint);
  $max-width: map.get($breakpoints, $max-breakpoint) - 1;

  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}
