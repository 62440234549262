@use "../abstracts" as *;
@use "../foundation/_utilities";
@use "../foundation/typography";

.button--cta.u-typo--button-cta {
  @extend .u-button;
  @extend .typo-button-label-large;
  background-color: var(--color-bg-reversed);
  border-radius: $border-radius-very-low;
  border: 0;
  color: var(--color-text-reversed);
  display: inline-block;
  padding-block: $space-1;
  padding-inline: $space-2_5;
  text-align: center;

  &:hover,
  &:active,
  &:focus-visible {
    color: var(--color-text-reversed);
  }

  &:hover {
    background-color: var(--color-bg-invert-subtle);
  }

  &:active {
    background-color: var(--color-bg-invert-subtler);
  }

  &:focus-visible {
    background-color: var(--color-bg-invert-subtle);
    outline: solid $border-radius-very-low var(--color-outline-focus);
    outline-offset: calc($border-radius-very-low / 2 * -1);
  }
}
