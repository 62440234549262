@use "../abstracts" as *;

.ws-outbrain > div {
  --outbrain-ar-padding: #{$space-4} #{$space-px-3};

  background-color: var(--color-bg-secondary);
  margin-bottom: var(--outbrain-ar-margin-bottom, $space-1);
  padding: var(--outbrain-ar-padding);

  @include media-breakpoint-up(md) {
    --outbrain-ar-padding: #{$space-5} #{$space-px-5};
  }

  @include media-breakpoint-up(lg) {
    --outbrain-ar-margin-bottom: $space-1_5;
    --outbrain-ar-padding: #{$space-8} #{$space-px-10};
  }

  // Outbrain widget has it's own styles which causes small "jump" when new content is loaded.
  // This is a workaround to prevent this jump calculating the proper horizonatal padding.
  // On the resolution greater than 1150px the layout for `ob-smartfeed-joker` is different:
  // 3 `ob-smartfeed-jokers` in a row: all with 300px width and 15px margin between them
  // additionally to it, there is a 3px horizontal padding on the `.ob-smartfeed-wrapper .ob-widget.ob-feed-layout`
  @media (min-width: 1150px) {
    --outbrain-content-width: calc(300px * 3 + 2 * 15px + 6px);
    --outbrain-ar-padding: #{$space-8}
      calc((100% - var(--outbrain-content-width)) / 2);
  }
}

.ob-smartfeed-joker {
  margin-block-start: #{$space-3};
}
