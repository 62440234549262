@use "sass:color";
@use "../abstracts" as *;

/* Semantic colors, default theme */
/* The sorting of the tokens should correspond to the Figma file for the design system. */
:root {
  /* Background Tokens */
  --color-bg-default: #{$white};
  --color-bg-secondary: #{$white};
  --color-bg-subtle: #{$neutral-020};
  --color-bg-emphasized-subtle: #{color.change($black, $alpha: 0.05)};
  --color-bg-emphasized-moderate: #{color.change($black, $alpha: 0.1)};
  --color-bg-emphasized-muted: #{color.change($black, $alpha: 0.15)};
  --color-bg-highlight: #{$red-050};
  --color-bg-highlight-muted: #{$red-060};
  --color-bg-highlight-emphasized: #{$red-045};
  --color-bg-highlight-subtle: #{$red-010};
  --color-bg-invert: #{$black};
  --color-bg-invert-emphasized-moderate: #{color.change($white, $alpha: 0.1)};
  --color-bg-invert-emphasized-subtle: #{color.change($white, $alpha: 0.15)};
  --color-bg-invert-emphasized-soft: #{color.change($white, $alpha: 0.35)};
  --color-bg-invert-soft: #{$neutral-095};
  --color-bg-invert-softer: #{color.change($neutral-095, $alpha: 0.8)};
  --color-bg-invert-subtle: #{color.change($black, $alpha: 0.7)};
  --color-bg-invert-subtler: #{color.change($black, $alpha: 0.8)};
  --color-bg-invert-inset: #{color.change($black, $alpha: 0.4)};
  --color-bg-reversed: #{$black};
  --color-bg-reversed-moderate: #{color.change($white, $alpha: 0.1)};
  --color-bg-reversed-subtle: #{color.change($white, $alpha: 0.15)};

  /* Border/Outline Tokens */
  --color-border-default: #{$neutral-040};
  --color-border-muted: #{$neutral-030};
  --color-border-subtle: #{$neutral-020};
  --color-border-highlight: #{$red-050};
  --color-border-invert-subtle: #{$neutral-060};
  --color-border-geo-highlight: #{$geo-green};
  --color-border-capital-highlight: #{$capital-gold};
  --color-outline-focus: #{$blue-focus};

  /* Text Tokens */
  --color-text-default: #{$black};
  --color-text-inset: #{$neutral-080};
  --color-text-moderate: #{$neutral-060};
  --color-text-muted: #{$neutral-050};
  --color-text-subtle: #{$neutral-040};
  --color-text-highlight: #{$red-050};
  --color-text-highlight-variant: #{$red-050};
  --color-text-highlight-geo: #{$geo-green};
  --color-text-highlight-capital: #{$capital-gold};
  --color-text-invert: #{$white};
  --color-text-invert-alpha: #{color.change($white, $alpha: 0.8)};
  --color-text-invert-muted: #{color.change($white, $alpha: 0.5)};
  --color-text-reversed: #{$white};

  /* Gradients Tokens */
  --color-bg-page-gradient-start: #{$white};
  --color-bg-page-gradient-end: #{$neutral-010};
  --color-fade-paywall-start: #{$white};
  --color-fade-paywall-middle: #{color.change($white, $alpha: 0.8)};
  --color-fade-paywall-end: #{color.change($white, $alpha: 0)};
}

/* Semantic colors, dark theme */
/* Colors that are different in light and dark mode. */
@media (prefers-color-scheme: dark) {
  :root {
    /* Background Tokens */
    --color-bg-default: #{$neutral-093};
    --color-bg-secondary: #{$neutral-092};
    --color-bg-subtle: #{$neutral-090};
    --color-bg-emphasized-subtle: #{color.change($white, $alpha: 0.15)};
    --color-bg-emphasized-moderate: #{color.change($white, $alpha: 0.1)};
    --color-bg-emphasized-muted: #{color.change($white, $alpha: 0.05)};
    --color-bg-highlight: #{$red-045};
    --color-bg-highlight-emphasized: #{$red-050};
    --color-bg-highlight-subtle: #{color.change($neutral-010, $alpha: 0.1)};
    --color-bg-invert-subtle: #{$neutral-040};
    --color-bg-invert-subtler: #{$neutral-030};
    --color-bg-reversed: #{$white};
    --color-bg-reversed-moderate: #{color.change($black, $alpha: 0.1)};
    --color-bg-reversed-subtle: #{color.change($black, $alpha: 0.05)};

    /* Border Tokens */
    --color-border-default: #{color.change($white, $alpha: 0.6)};
    --color-border-muted: #{color.change($white, $alpha: 0.1)};
    --color-border-subtle: #{$neutral-090};
    --color-border-highlight: #{$neutral-050};
    --color-border-invert-subtle: #{color.change($white, $alpha: 0.6)};

    /* Text Tokens */
    --color-text-default: #{$neutral-010};
    --color-text-inset: #{$neutral-040};
    --color-text-moderate: #{$neutral-050};
    --color-text-subtle: #{$neutral-060};
    --color-text-highlight: #{$neutral-050};
    --color-text-highlight-variant: #{$neutral-010};
    --color-text-highlight-geo: #{$neutral-010};
    --color-text-highlight-capital: #{$neutral-010};
    --color-text-reversed: #{$black};

    /* Gradients Tokens */
    --color-bg-page-gradient-start: #{$neutral-093};
    --color-bg-page-gradient-end: #{$neutral-093};
    --color-fade-paywall-start: #{$neutral-093};
    --color-fade-paywall-middle: #{color.change($neutral-093, $alpha: 0.8)};
    --color-fade-paywall-end: #{color.change($neutral-093, $alpha: 0)};
  }
}
