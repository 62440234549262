@use "../abstracts" as *;

.sub-navigation {
  padding-bottom: $space-5;

  @include media-breakpoint-up(md) {
    padding-bottom: $space-6;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $space-8;
  }

  .page--index & {
    padding-inline: $space-px-3;

    @include media-breakpoint-up(md) {
      padding-inline: $space-px-5;
    }

    @include media-breakpoint-up(lg) {
      padding-inline: $space-px-10;
    }
  }
}
