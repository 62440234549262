@use "../abstracts" as *;

.links--alphabet {
  padding: $space-3;
  background: var(--color-bg-default);

  @include media-breakpoint-up(md) {
    padding: $space-3 $space-5;
  }

  @include media-breakpoint-up(lg) {
    padding: $space-4 $space-10 $space-3;
  }

  .links__items {
    display: flex;
    flex-wrap: wrap;
    gap: $space-1_5;

    .links__item {
      .links__item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        /* need to work with static values for width and height w/ padding chip item wouldn't be square */
        width: rem(37px);
        height: rem(37px);
        padding-top: 2px;
        border-radius: $space-0_5;
        border: 1px solid var(--color-border-default);
        color: var(--color-text-default);
        cursor: pointer;
        text-decoration: none;
        transition: all var(--default-transition-time) ease;

        &:not(.links__item-link--active) {
          &:hover {
            background: var(--color-bg-emphasized-subtle);
          }

          &:focus {
            background: var(--color-bg-emphasized-moderate);
          }
        }

        &--active {
          background: var(--color-bg-invert);
          color: var(--color-text-invert);
          border-color: var(--color-bg-invert);
        }
      }
    }
  }
}
