@use "../abstracts" as *;

ws-gallery:not(:defined) :is([slot="previous"], [slot="next"]) {
  display: none;
}

.ws-gallery {
  --ws-gallery-base-min-height: #{rem(44px) + $space-3}; // Button size + spacing
  --ws-gallery-brand-identifier-height: #{rem(44px)};
  --ws-gallery-current-item-color: var(--color-text-invert);
  --ws-gallery-current-item-font-weight: #{$font-weight-700};
  --ws-gallery-item-counter-background-color: var(--color-bg-invert-inset);
  --ws-gallery-item-counter-color: var(--color-text-invert-alpha);
  --ws-gallery-item-counter-padding: calc(#{$space-1_5} / 2) #{$space-1_5};
  --ws-gallery-space-horizontal: #{$space-3};
  --ws-gallery-space-vertical: #{$space-3};

  .group-inline-gallery & {
    --ws-gallery-base-padding: 0 var(--ws-gallery-space-horizontal)
      var(--ws-gallery-space-vertical);
  }

  &:has(ws-clamp[expanded]) {
    --ws-gallery-item-counter-display: none;
  }

  @include media-breakpoint-up(md) {
    --ws-gallery-base-min-height: 0;
    --ws-gallery-space-horizontal: #{$space-5};
    --ws-gallery-space-vertical: #{$space-5};
  }

  @include media-breakpoint-up(lg) {
    --ws-gallery-space-horizontal: #{$space-10};
  }

  .article__body & {
    @include media-breakpoint-up(md) {
      /* one column and one gap of a 12 column grid */
      --ws-gallery-space-horizontal: calc(
        calc((100% - 11 * var(--article-column-gap)) / 12) +
          var(--article-column-gap)
      );
    }
  }

  &__headline-wrapper {
    color: var(--color-text-invert);
    display: flex;
    flex-direction: column;
    gap: $space-1_5;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      min-height: rem(44px);
      width: calc(100% - 124px);
    }
  }

  &__divider {
    color: var(--color-border-invert-subtle);
    font-size: $font-size-18;
  }

  &__brand-identifier {
    align-items: center;
    display: flex;
    height: rem(44px);

    @include media-breakpoint-up(md) {
      height: auto;

      &:not(:has(.brand-identifier)) {
        display: none;
      }
    }

    .brand-identifier {
      height: $space-2;

      @include media-breakpoint-up(md) {
        height: $space-2_5;
      }
    }
  }

  &__button {
    align-items: center;
    aspect-ratio: 1;
    backdrop-filter: blur(7.2px);
    background-color: var(--color-bg-invert-emphasized-subtle);
    border-radius: 50%;
    color: var(--color-text-invert);
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: var(--ws-gallery-button-opacity, 1);
    text-decoration: none;
    transition: background-color var(--default-transition-time);
    width: rem(44px);
    z-index: 1;

    &[inert] {
      --ws-gallery-button-opacity: 0.5;
    }

    &:not([inert]) {
      @media (hover: hover) {
        &:hover {
          background-color: var(--color-bg-invert-emphasized-soft);
        }
      }

      &:focus-visible {
        background-color: var(--color-bg-invert-emphasized-soft);
      }

      &:active {
        background-color: var(--color-bg-invert-emphasized-moderate);
      }
    }
  }

  &__button-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: $font-size-24;
  }
}
