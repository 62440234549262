@use "../abstracts" as *;

.teaser-plaintext {
  display: flex;
  flex-direction: column;
  gap: $space-0_5;
  position: relative;

  &__meta {
    display: flex;
    gap: $space-1_5;
  }

  &__time {
    color: var(--color-text-inset);
  }

  &__link {
    color: var(--color-text-default);
    text-decoration: none;

    // to make the whole teaser clickable
    &::after {
      content: "";
      inset: 0;
      position: absolute;
    }
  }
}
