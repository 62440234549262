@use "../abstracts" as *;
@use "../foundation/icons";
@use "../foundation/typography";

.affiliate-widget {
  align-items: start;
  background: var(--color-bg-subtle);
  clear: both; // to prevent the float elements placed next to the widget (e.g. ads) to break the layout
  display: grid;
  grid-template-areas:
    "image image"
    "discount affiliate-link"
    "title title"
    "partner-logo price"
    "button button";
  grid-template-rows: max-content auto;
  margin: $space-3 0;
  padding: $space-3;
  position: relative;

  // Edge case:
  // the switch to "md" breakpoint happens at 560px since the "portrait" layout look odd
  // on the bigger small resolutions (aligned with the design team)
  @media screen and (min-width: 560px) {
    column-gap: $space-4;
    grid-template-areas:
      "image discount affiliate-link"
      "image title title"
      "image partner-logo price"
      "image button button";
    grid-template-columns: rem(201px) auto; // 201px is taken from the design
    grid-template-rows: max-content 1fr max-content max-content;
    margin: $space-6 0;
    padding-right: $space-4;
  }

  @include media-breakpoint-up(lg) {
    column-gap: $space-5;
    grid-template-columns: rem(213px) auto; // 213px is taken from the design
    margin: $space-8 0;
    padding-right: $space-5;
  }

  &__image-wrapper {
    background: var(--color-bg-default);
    grid-area: image;
    margin-bottom: $space-3;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__image {
    aspect-ratio: 1;
    object-fit: contain;
    width: 100%;
  }

  &__info-link {
    @extend .typo-ad-label;
    align-self: center;
    color: var(--color-text-inset);
    grid-area: affiliate-link;
    justify-self: end;
    text-decoration: none;

    &::after {
      @extend .icon-element;
      content: $icon-info;
      display: inline-flex;
      font-size: 1.3em;
      padding-left: $space-0_5;
      position: relative;
      top: 0.1em;
    }
  }

  &__percentage-saved {
    @extend .typo-info-small-bold;
    align-self: center;
    background: var(--color-bg-highlight);
    border-radius: $space-0_5;
    color: var(--color-text-invert);
    grid-area: discount;
    justify-self: start;
    padding: 0.25rem 0.55rem; // magic numbers taken from the design

    @include media-breakpoint-up(md) {
      padding: 0.35rem 0.55rem; // magic numbers taken from the design
    }
  }

  &__content {
    display: contents;
  }

  &__title {
    @extend .typo-teaser-affiliate-head;
    grid-area: title;
    margin: $space-1_5 0;
    text-wrap: balance;
  }

  &__partner-logo {
    align-self: center;
    display: flex; // for the proper alignment of the logo
    grid-area: partner-logo;
    justify-self: start;

    // Covers the case when the logo is a background image
    &[style*="background-image"] {
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: contain;
      height: 24px;
      width: 60px;
    }
  }

  &__price {
    @extend .typo-teaser-affiliate-info-bold;
    align-self: center;
    grid-area: price;
    justify-self: end;
    white-space: nowrap;

    &:has(.affiliate-widget__list-price) {
      color: var(--color-text-highlight-variant);
    }
  }

  &__list-price {
    @extend .typo-teaser-affiliate-info;
    color: var(--color-text-inset);
    margin-left: $space-1;
    text-decoration: line-through;

    @include media-breakpoint-up(md) {
      margin-left: $space-1_5;
    }
  }

  & a.affiliate-widget__button {
    @extend .typo-button-label-large;
    background: var(--color-bg-reversed);
    grid-area: button;
    margin-top: $space-3;
    min-height: rem(44px);
    padding: $space-1_5;

    @include media-breakpoint-up(lg) {
      margin-top: $space-4;
    }
  }
}
