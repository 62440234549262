@use "../abstracts" as *;

.article {
  /* These values are also used for the caption in the image-element as lead-content. */
  --article-grid-template-columns: #{repeat(4, 1fr)};
  --article-column-gap: #{$space-px-2};
  --article-padding-inline: #{$space-px-3};
  --article-body-grid-column: #{1 / -1};

  display: grid;
  grid-template-columns: var(--article-grid-template-columns);
  column-gap: var(--article-column-gap);
  padding-inline: var(--article-padding-inline);

  &:not(.page-opulent__head-inner) {
    background-color: var(--color-bg-default);
  }

  @include media-breakpoint-up(md) {
    --article-grid-template-columns: #{repeat(12, 1fr)};
    --article-column-gap: #{$space-px-4};
    --article-padding-inline: #{$space-px-5};
    --article-body-grid-column: #{2 / -2};
    /* The article body spans 10 columns. This calculation gives the width of one column. */
    --article-body-grid-column-width: calc(
      (100% - 9 * var(--article-column-gap)) / 10
    );
    /* Embedded elements, such as teasers, can be negatively indented on the left and right to cover the entire grid. */
    --article-body-reverse-indent-1-column: calc(
      -1 * var(--article-body-grid-column-width) - var(--article-column-gap)
    );
  }

  @include media-breakpoint-up(lg) {
    --article-column-gap: #{$space-px-5};
    --article-padding-inline: #{$space-px-14};

    max-width: $max-content-width;
  }

  &__header,
  &__body {
    grid-column: var(--article-body-grid-column);
  }

  &__header {
    @include media-breakpoint-up(xl) {
      grid-column: 1 / -1;
    }
  }

  &__body {
    padding-bottom: $space-8;

    > .ad-tag-container--outstream_1 {
      margin: $space-px-5 0;

      @include media-breakpoint-up(md) {
        margin: $space-px-6 0;
      }

      @include media-breakpoint-up(lg) {
        margin: $space-px-8 0;
      }
    }
  }

  &__lead-content {
    grid-column: 1 / -1;
    margin-inline: calc(-1 * var(--article-padding-inline));
    margin-block: 0 $space-5;

    @include media-breakpoint-up(md) {
      margin-block-end: $space-6;
    }

    @include media-breakpoint-up(xl) {
      margin-block-end: $space-8;
    }
  }

  &__end {
    background-color: var(--color-bg-page-gradient-end);
    padding-block: $space-1_5;
    position: relative;

    @include media-breakpoint-up(md) {
      padding-block: $space-3;
    }

    &::before {
      background: linear-gradient(
        var(--color-bg-page-gradient-start),
        var(--color-bg-page-gradient-end)
      );
      content: "";
      height: rem(430px);
      inset-inline: 0;
      position: absolute;
      top: -(rem(370px));
      z-index: -1;
    }

    &::after {
      background-color: var(--color-bg-page-gradient-end);
      content: "";
      inset: 0;
      position: absolute;
      z-index: -2;
    }

    &::before,
    &::after {
      @include media-breakpoint-up(lg) {
        inset-inline: calc(-1 * ((100vw - $max-content-width) / 2));

        /* For ad specials, the background color is set by the advertising and should not be overlaid by the gradient. */
        .page--with-adspecial & {
          inset-inline: 0;
        }
      }
    }
  }
}
