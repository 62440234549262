@use "../abstracts" as *;

.author-element {
  margin-inline: $space-px-3;

  @include media-breakpoint-up(md) {
    margin-inline: $space-px-7;
  }

  @include media-breakpoint-up(lg) {
    margin-inline: $space-px-14;
  }

  &__name {
    margin-bottom: $space-px-1;

    text-align: center;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-px-3;
    }
  }

  &__image-wrapper {
    border-radius: 50%;
    height: 120px;
    margin-block: $space-px-3 $space-px-4;
    margin-inline: auto;
    width: 120px;

    .image-element {
      height: 100%;

      img {
        border-radius: 50%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__info-divider {
    color: var(--color-text-moderate);
  }

  &__info-section {
    display: flex;
    flex-direction: row;
    gap: $space-px-3;
    justify-content: center;
    margin-bottom: $space-px-3;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-px-5;
    }
  }

  &__social-list {
    color: var(--color-text-subtle);
    display: flex;
    flex-direction: row;
    gap: $space-px-3;
  }
}
