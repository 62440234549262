@use "sass:math";
@use "../abstracts" as *;
@use "../foundation/icons";

.breadcrumb {
  --ws-scroller-mask-image-both: linear-gradient(
    to right,
    transparent 0,
    #000 #{$space-6} calc(100% - #{$space-6}),
    transparent 100%
  );
  --ws-scroller-mask-image-next: linear-gradient(
    to right,
    #000 calc(100% - #{$space-6}),
    transparent 100%
  );
  --ws-scroller-mask-image-previous: linear-gradient(
    to right,
    transparent 0,
    #000 #{$space-6}
  );

  .page--index & {
    margin-top: $space-1_5;
    padding: 0 $space-px-3;

    @include media-breakpoint-up(md) {
      margin-top: $space-2;
      padding: 0 $space-px-5;
    }

    @include media-breakpoint-up(lg) {
      margin-top: $space-3;
      padding: 0 $space-px-10;
    }
  }

  &__list {
    display: flex;
    margin-bottom: $space-1_5;
    overflow: auto scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: $space-2;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-3;
    }
  }

  &__item {
    display: none;

    &:first-child,
    &:last-child {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &:not(:last-child)::after {
      @extend .icon-element;
      color: var(--color-text-muted);
      content: $icon-chevron-right;
      margin: 0 $space-1;
    }

    .page-opulent &:after {
      color: var(--color-text-invert);
    }
  }

  &__link,
  &__headline {
    align-items: center;
    color: var(--color-text-moderate);
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .page-opulent & {
      color: var(--color-text-invert);
    }
  }

  .page-opulent & {
    margin: 0 $space-px-3;

    @include media-breakpoint-up(md) {
      margin: 0 $space-px-5;
    }

    @include media-breakpoint-up(xl) {
      margin-left: auto;
      margin-right: auto;
      max-width: $max-content-width;
    }
  }
}
