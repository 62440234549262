@use "../abstracts" as *;

@include media-breakpoint-up(lg) {
  .article__body {
    ws-adtag:is(
        #teaser_11,
        #teaser_12,
        #teaser_13,
        #teaser_17,
        #teaser_18,
        #teaser_19
      ) {
      div {
        min-width: 300px;
      }
    }
  }
}

ws-gallery ws-adtag#mobile_1 {
  align-content: space-around;
  display: flex !important; // to overwrite inline rule
  flex-wrap: wrap;
  height: 85%;
  width: 95%;
}

.article__body ws-adtag:is(#teaser_11, #teaser_12, #teaser_13):has(.teaser) {
  margin-block: $space-3 $space-4;
}

@include media-breakpoint-down(md) {
  .article__body ws-adtag:is(#teaser_23, #teaser_24, #teaser_25) {
    margin-block: $space-2 $space-3;
  }
  .article__body ws-adtag:is(#teaser_11, #teaser_12, #teaser_13):has(.teaser) {
    margin-block: $space-6 $space-4;
    // Actually, block-start is supposed to be $space-3.
    // But there is this small marker text "Anzeige" inside the margin, so we need $space-6 for a visual result of $space-3.
  }
}

// stop gallery adtags showing up inside article on non-mobile
@include media-breakpoint-up(md) {
  .subheadline-element + ws-adtag#mobile_1,
  .text-element + ws-adtag#mobile_1 {
    display: none;
  }
}
