/* Inspired by: https://piccalil.li/blog/a-more-modern-css-reset/ */

@use "../abstracts" as *;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  font-size: $font-size-16;
  scroll-behavior: smooth;
  color: inherit;

  @media (prefers-reduced-motion: reduce) {
    & {
      scroll-behavior: auto;
    }
  }
}

/* Remove default margin in favour of better control in authored CSS */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core body defaults */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  min-height: 100vh;
  font-family: $font-family-stern;
  font-weight: $font-weight-400;
  font-stretch: $font-stretch-100;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-normal;
  background: var(
    --color-bg-default
  ); /* to show background color for iPhone controls on dark mode */

  &:has(.dialog[open]),
  &:has(ws-drawer[opened]) {
    overflow: hidden;
  }

  &:has(#page-menu[opened]) {
    overflow: hidden;
  }
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: $line-height-120;
}

/* Avoid orphans in text blocks. */
p {
  text-wrap: pretty;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: currentColor;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove default button styling. */
:where(button) {
  background-color: transparent;
  border-width: 0;
  color: inherit;
  cursor: pointer;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}
