@use "../abstracts" as *;

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: $space-1;

  &__link {
    --ws-link-text-decoration: none;
    background-color: var(--color-bg-subtle);
    border-radius: $space-4;
    color: var(--color-text-inset);
    display: inline-block;
    padding: $space-0_5 $space-1;
    text-decoration: none;

    &--presenter {
      display: flex;
      gap: $space-0_5;

      .ad-labels {
        color: var(--color-text-muted);
      }
    }
  }
}
