@use "../abstracts" as *;

.footer {
  padding: $space-8 $space-3;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: $space-10 $space-5;
  }

  &::after {
    background-color: var(--color-bg-page-gradient-end);
    content: "";
    inset: 0;
    position: absolute;
    z-index: -2;

    @include media-breakpoint-up(lg) {
      inset-inline: calc(-1 * ((100vw - $max-content-width) / 2));

      /* For ad specials, the background color is set by the advertising and should not be overlaid by the gradient. */
      .page--with-adspecial & {
        inset-inline: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-4;
  }

  &__top-bar {
    align-items: center;
    align-self: stretch;
    display: flex;
  }

  &__to-top {
    color: currentColor;
    font-size: $font-size-32;
    text-decoration: none;
  }

  &__logo {
    aspect-ratio: 46 / 64;
    background: var(--color-bg-highlight) var(--stern-logo) center/contain
      no-repeat;
    height: 3.25rem;
    margin: 0 auto;
  }

  &__nav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 44.5rem;
    row-gap: $space-1_5;
  }

  &__nav-item {
    display: inline-flex;

    &:not(:last-child)::after {
      color: var(--color-text-muted);
      content: "⋅";
      margin: 0 $space-1;
    }
  }

  &__nav-link {
    color: var(--color-text-inset);
    text-decoration: none;
  }

  &__social-media-list {
    color: var(--color-text-default);
    display: flex;
    font-size: $font-size-24;
    gap: $space-3;
    padding: $space-1_5 0;
  }

  &_social-media-link {
    transition: color var(--default-transition-time);

    &:hover,
    &:focus-within {
      color: var(--color-text-highlight);
    }
  }

  &__copyright {
    color: var(--color-text-muted);
  }

  .ad-tag-container {
    margin-bottom: 0;
    margin-top: $space-2_5;
  }
}
