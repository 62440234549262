@use "../abstracts" as *;

.ws-drawer {
  --ws-drawer-background-color: var(--color-bg-default);
  --ws-drawer-close-button-background-color--active: var(
    --color-bg-emphasized-moderate
  );
  --ws-drawer-close-button-background-color--hover: var(
    --color-bg-emphasized-subtle
  );
  --ws-drawer-close-button-margin: 0 calc(-1 * #{$space-2});
  --ws-drawer-close-button-padding: #{$space-1} #{$space-2};
  --ws-drawer-close-icon-content: "#{$icon-close}";
  --ws-drawer-close-icon-font-family: "#{$font-family-icons}";
  --ws-drawer-close-text-font-family: #{$font-family-stern};
  --ws-drawer-color: var(--color-text-default);
  --ws-drawer-header-padding: 1.8rem #{$space-3}; /* 1.8rem - magic number - to make it the same height as "header__navigation" */

  @include media-breakpoint-up(lg) {
    --ws-drawer-header-padding: 2.2rem #{$space-3}; /* 2.2rem - magic number - to make it the same height as "header__navigation" */
  }
}

ws-drawer:not(:defined) {
  display: none;
}
