@use "../abstracts" as *;
@use "../foundation/icons";

.deep-link-title {
  color: inherit;
  display: flex;
  flex-direction: column-reverse;
  gap: $space-2_5;
  margin-bottom: $space-5;
  text-decoration: none;

  @include media-breakpoint-up(md) {
    gap: calc($space-5 + $space-0_5);
    margin-bottom: $space-6;
  }

  @include media-breakpoint-up(lg) {
    gap: $space-2_5;
  }

  @include media-breakpoint-up(xl) {
    gap: $space-3;
    margin-bottom: $space-8;
  }

  &:hover {
    color: var(--color-text-inset);
  }

  &__button {
    &::before {
      @extend .icon-element;
      content: $icon-arrow-left;
      display: inline;
      font-size: rem(10px);
      margin-right: $space-1;
      transform: translateY(-0.1em);
    }
  }
}
