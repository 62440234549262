@use "sass:color";
@use "../abstracts" as *;

.article-navigation {
  margin: $space-3 0 $space-1;

  @include media-breakpoint-up(md) {
    margin: $space-4 0 $space-1-5;
  }

  @include media-breakpoint-up(lg) {
    margin: $space-6 0 $space-2;
  }

  a {
    text-decoration: none;
    color: var(--color-text-default);
  }

  &__headline {
    display: flex;
    align-items: center;
    margin-bottom: $space-2;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-3;
    }

    [class*="icon-"] {
      font-size: rem(21px);

      &::before {
        color: var(--color-text-muted);
      }

      @include media-breakpoint-up(lg) {
        &::before {
          transition: all 0.3s ease;
        }
      }
    }
  }

  &__headline-link {
    margin-right: $space-1;

    @include media-breakpoint-up(lg) {
      &::before {
        content: "";
        inset: 0;
        position: absolute;
      }

      &:hover {
        ~ [class*="icon-"] {
          &::before {
            color: var(--color-text-highlight);
          }
        }
      }
    }
  }

  &__marketing {
    display: flex;
    gap: $space-1;
    margin-bottom: $space-3;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-2_5;
    }
  }

  &__list {
    gap: $space-1;

    &:not(.ws-scroller__list) {
      margin-bottom: $space-1_5;
      display: flex;
      flex-wrap: wrap;
    }

    &-link {
      background: var(--u-button-background);
      --u-button-border: var(--color-border-subtle);
      padding: calc($space-1 + 2px) $space-1_5 $space-1;
      white-space: nowrap;

      .group-teaserblock--with-background-image & {
        --u-button-border: var(--color-border-default);

        @media (prefers-color-scheme: light) {
          --color-bg-emphasized-subtle: #{color.change($white, $alpha: 0.15)};
          --color-bg-emphasized-moderate: #{color.change($white, $alpha: 0.1)};
        }
      }
    }
  }
}
