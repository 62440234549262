@use "../abstracts" as *;

.subheadline-element {
  margin-bottom: $space-2;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-2_5;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-3;
  }
}
