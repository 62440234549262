@use "../abstracts" as *;

.teaser-badge {
  align-items: center;
  display: var(--teaser-badge-display);
  gap: $space-0_5;
  line-height: var(--teaser-badge-icon-font-size);

  &--as-chip {
    --teaser-badge-display: flex;
    --teaser-badge-icon-font-size: #{$font-size-20};

    backdrop-filter: blur($space-0_5);
    background: var(--color-bg-invert-inset);
    border-radius: $space-0_5;
    color: var(--color-text-invert);
    padding: rem(6px) $space-1;
  }

  &--as-text {
    --teaser-badge-display: inline-flex;
    --teaser-badge-icon-font-size: #{$font-size-16};

    color: var(--color-text-inset);

    .group-teaserblock--with-background-image & {
      color: var(--color-text-invert-alpha);
    }
  }

  &__icon {
    font-size: var(--teaser-badge-icon-font-size);
    letter-spacing: 0;
  }
}
