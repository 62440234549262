@use "../abstracts" as *;

.page {
  --page-header-height: #{$header-height};

  background-color: var(--color-bg-default);
  color: var(--color-text-default);
  overflow-x: clip;

  @include media-breakpoint-up(xl) {
    --page-header-height: #{$header-height-on-xl};
  }

  &__header {
    /* For ad specials with a colored background, the header should keep the default color. */
    background-color: var(--color-bg-default);
  }

  &__content {
    position: relative;
    z-index: 1;

    /* for skyscraper ad */
    @include media-breakpoint-up(lg) {
      display: flex;
      margin-inline: auto;
      max-width: $max-content-width;
    }

    &-inner {
      @include media-breakpoint-up(lg) {
        flex-shrink: 0;
        width: $max-content-width;
      }
    }
  }

  &__aside {
    width: 0;
  }

  &__main {
    margin-top: $space-2;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-top: $space-3;
    }

    @include media-breakpoint-up(lg) {
      margin-top: $space-6;
    }

    @include media-breakpoint-up(xl) {
      margin-top: $space-8;
    }

    .page--index & {
      margin-top: 0;
    }
  }

  &--index {
    --position-bg-page-gradient-start: #{rem(358px)};
    --position-bg-page-gradient-end: #{rem(358px) + rem(430px)};

    background: linear-gradient(
      var(--color-bg-page-gradient-start) 0,
      var(--color-bg-page-gradient-start)
        calc(var(--page-header-height) + var(--position-bg-page-gradient-start)),
      var(--color-bg-page-gradient-end)
        calc(var(--page-header-height) + var(--position-bg-page-gradient-end)),
      var(--color-bg-page-gradient-end) 100%
    );

    .page__main {
      /* For ad specials with a colored background, the content should keep the gradient. */
      background: linear-gradient(
        var(--color-bg-page-gradient-start) 0,
        var(--color-bg-page-gradient-start)
          var(--position-bg-page-gradient-start),
        var(--color-bg-page-gradient-end) var(--position-bg-page-gradient-end),
        var(--color-bg-page-gradient-end) 100%
      );
    }
  }
}

/* temporary element */
.element {
  background-color: #ece0cb;
  border: 2px solid #e0cba8;
  margin: 10px 0;
  padding: 5px 10px;
}
