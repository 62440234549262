@use "../abstracts" as *;

.bookmarklist-empty-state {
  --empty-state-asset-dark: url(@client/images/bookmarklist-asset-dark.svg);
  --empty-state-asset: url(@client/images/bookmarklist-asset.svg);
  --empty-state-image-gap: #{$space-4};
  --empty-state-image-height: #{rem(114px)};
  --empty-state-margin-block-end: #{$space-3};
  --empty-state-padding-block: #{$space-5};
  --empty-state-padding-inline: #{$space-3};

  background-color: var(--color-bg-secondary);
  margin-block-end: var(--empty-state-margin-block-end);
  padding-block: var(--empty-state-padding-block);
  padding-inline: var(--empty-state-padding-inline);

  @include media-breakpoint-up(md) {
    --empty-state-padding-inline: #{$space-8};
  }

  @include media-breakpoint-up(lg) {
    --empty-state-padding-inline: #{$space-10};
  }

  &__image {
    aspect-ratio: 1;
    background: var(--empty-state-asset) center / 100% no-repeat;
    height: var(--empty-state-image-height);
    margin-block-end: var(--empty-state-image-gap);
    margin-inline: auto;

    @include media-breakpoint-up(lg) {
      --empty-state-image-height: #{rem(170px)};
    }

    @media (prefers-color-scheme: dark) {
      --empty-state-asset: var(--empty-state-asset-dark);
    }
  }

  &__content {
    text-align: center;
  }

  /* If the bookmarklist is placed in a teaserblock, all outer spacing should be removed. */
  .group-teaserblock & {
    --empty-state-margin-block-end: 0;
    --empty-state-padding-block: 0;
    --empty-state-padding-inline: 0;
  }
}
