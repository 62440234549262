@use "../abstracts" as *;

.dialog {
  border-radius: 0.125rem;
  border: 0;
  max-width: calc(100% - 2rem);
  padding: 0;

  @include media-breakpoint-up(md) {
    max-width: rem(576px);
  }

  &::backdrop {
    background: var(--color-bg-invert);
    opacity: 0.4;
  }

  &__close-button {
    color: var(--color-text-default);
    cursor: pointer;
    font-size: $font-size-20;
    padding: $space-1;
    position: absolute;
    right: $space-3;
    top: $space-3;
    z-index: 1;
  }
}
