@use "../abstracts" as *;

[class^="icon-"]::before,
[class*=" icon-"]::before,
.icon-element {
  font-size: inherit;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-icons !important;
  font-style: normal;
  font-weight: $font-weight-400;
  display: flex;
  text-decoration: inherit;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

$icons: (
  "icon-arrow-down": $icon-arrow-down,
  "icon-arrow-left": $icon-arrow-left,
  "icon-arrow-right": $icon-arrow-right,
  "icon-arrow-up": $icon-arrow-up,
  "icon-arrow-up-right": $icon-arrow-up-right,
  "icon-audio": $icon-audio,
  "icon-bookmark": $icon-bookmark,
  "icon-bookmark-filled": $icon-bookmark-filled,
  "icon-menu": $icon-menu,
  "icon-caret-down": $icon-caret-down,
  "icon-caret-left": $icon-caret-left,
  "icon-caret-right": $icon-caret-right,
  "icon-caret-up": $icon-caret-up,
  "icon-cart": $icon-cart,
  "icon-check": $icon-check,
  "icon-chevron-down": $icon-chevron-down,
  "icon-chevron-left": $icon-chevron-left,
  "icon-chevron-right": $icon-chevron-right,
  "icon-chevron-up": $icon-chevron-up,
  "icon-close": $icon-close,
  "icon-email": $icon-email,
  "icon-eye": $icon-eye,
  "icon-eye-closed": $icon-eye-closed,
  "icon-facebook": $icon-facebook,
  "icon-gallery": $icon-gallery,
  "icon-games": $icon-games,
  "icon-headphones": $icon-headphones,
  "icon-headset": $icon-headset,
  "icon-home": $icon-home,
  "icon-instagram": $icon-instagram,
  "icon-link": $icon-link,
  "icon-lock": $icon-lock,
  "icon-magazines": $icon-magazines,
  "icon-placeholder": $icon-placeholder,
  "icon-podcast": $icon-podcast,
  "icon-product-comparisons": $icon-product-comparisons,
  "icon-profile": $icon-profile,
  "icon-push-notification": $icon-push-notification,
  "icon-quote": $icon-quote,
  "icon-share-android": $icon-share-android,
  "icon-share-ios": $icon-share-ios,
  "icon-sign-in": $icon-sign-in,
  "icon-sign-out": $icon-sign-out,
  "icon-stern-studien": $icon-stern-studien,
  "icon-tiktok": $icon-tiktok,
  "icon-twitter": $icon-x,
  "icon-video": $icon-video,
  "icon-whatsapp": $icon-whatsapp,
  "icon-x": $icon-x,
  "icon-xing": $icon-xing,
  "icon-youtube": $icon-youtube,
  "icon-check-default": $icon-check-default,
  "icon-info": $icon-info,
  "icon-list-bullets": $icon-list-bullets,
  "icon-lightbox": $icon-lightbox,
  "icon-pause": $icon-pause,
  "icon-play": $icon-play,
  "icon-user-list": $icon-user-list,
  "icon-external": $icon-external,
  "icon-waveform": $icon-waveform,
  "icon-affiliate": $icon-affiliate,
);

@each $name, $glyph in $icons {
  .#{$name}::before {
    content: $glyph;
  }
}
